import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Chemical mechanical planarization`}</h4>
    <p>{`After etching and cleaning, the wafer surface is flattened in a process called chemical mechanical planarization (CMP) to allow a new layer of features to be added. CMP tools are critical for chip fabrication, but not as complex as other tools, such as lithography and deposition tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      